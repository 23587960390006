import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

import ashlogo from '../images/Home/ashlogo2.png';
import facebook from '../images/Home/facebook.png';
import instagram from '../images/Home/instagram.png';
import linkedin from '../images/Home/linkedin.png';

const Header = () => {
	return (
		<header className="header">
			<div className="container header-container">
				<div className="logo">
					<Link to="/">
						<img src={ashlogo} alt="Logo" />
					</Link>
					<h2>American Students For Health</h2>
				</div>
				<div className="nav-social-container">
					<nav>
						<ul className="nav-links">
							<li>
								<Link to="/">Home</Link>
							</li>
							<li>
								<div className="dropdown">
									<button className="dropbtn">Past Initiatives</button>
									<div className="dropdown-content">
										<Link to="/Tryavna">Campaign 1: Tryavna</Link>
										<Link to="/Kyiv">Campaign 2: Kyiv</Link>
										<Link to="/Lviv">Campaign 3: Lviv</Link>
										<Link to="/Lviv2">Campaign 4: Lviv</Link>
										<Link to="/Lviv3">Campaign 5: Lviv</Link>
									</div>
								</div>
							</li>
						</ul>
					</nav>
					<div className="social-links">
						<a href="https://www.facebook.com/AmericanStudentsForHealth/" className="social-link">
							<img src={facebook} alt="FB" className="social-icon" />
						</a>
						<a href="https://www.instagram.com/americanstudentsforhealth/" className="social-link">
							<img src={instagram} alt="IG" className="social-icon" />
						</a>
						<a href="https://www.linkedin.com/company/american-students-for-health/" className="social-link">
							<img src={linkedin} alt="IN" className="social-icon" />
						</a>
					</div>
				</div>
			</div>
		</header>
	);
}

export default Header;
