import React, { useState, useEffect } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import './InitiativePage.css';
import { useLocation } from 'react-router-dom';
import Footer from './Footer';

const InitiativePage = ({ title, content, gallery, additional }) => {
	const [galleryImages, setGalleryImages] = useState([]);
	const [additionalSections, setAdditionalSections] = useState([]);
	const location = useLocation();

	useEffect(() => {
		setAdditionalSections([]);
		const loadGalleryImages = async () => {
			const images = await Promise.all(
				gallery.map(async (path) => {
					try {
						const image = await import(`../images/${path}`);
						return { 
							original: image.default, 
							thumbnail: image.default 
						};
					} catch (err) {
						console.error(`Failed to load image at ${path}`, err);
						return null;
					}
				})
			);
			setGalleryImages(images.filter(Boolean));
		};
		loadGalleryImages();
		if (additional) {
			const loadAdditionalImages = async () => {
				const sections = await Promise.all(
					additional.map(async (section) => {
						const galleryImages = await Promise.all(
							section.gallery.map(async (path) => {
								try {
									const image = await import(`../images/${path}`);
									return { 
										original: image.default, 
										thumbnail: image.default 
									};
								} catch (err) {
									console.error(`Failed to load image at ${path}`, err);
									return null;
								}
							})
						);
						return { ...section, gallery: galleryImages.filter(Boolean) };
					})
				);
				setAdditionalSections(sections);
			};
			loadAdditionalImages();
		}
	}, [gallery, additional]);

	const renderContent = (content) => {
		return content.map((item, index) => (
			typeof item === 'string' ? <p key={index}>{item}</p> : <div key={index}>{item}</div>
		));
	};
	
	return (
		<div className="initiative-page">
			<div className="container">
				<h1>{title}</h1>
				{renderContent(content)}
			</div>
			<div className="gallery-background">
				<div className="container">
					<div className="gallery-section">
						<div className="image-gallery-wrapper">
							<ImageGallery 
								items={galleryImages} 
								showThumbnails={true} 
								showFullscreenButton={false} 
								showPlayButton={false} 
								useBrowserFullscreen={false}
							/>
						</div>
					</div>
				</div>
			</div>
			{additionalSections.map((section, idx) => (
				<div key={idx}>
					<div className="container">
						<h2>{section.title}</h2>
						{renderContent(section.content)}
					</div>
					{section.gallery.length > 0 &&
					<div className="gallery-background">
						<div className="container">
							<div className="gallery-section">
								<div className="image-gallery-wrapper">
									<ImageGallery 
									items={section.gallery} 
									lazyLoad 
									showThumbnails={true} 
									showFullscreenButton={false} 
									showPlayButton={false} 
									useBrowserFullscreen={false}
									/>
								</div>
							</div>
						</div>
					</div>
					}
				</div>
			))}
			<Footer />
		</div>
	);
};

export default InitiativePage;
