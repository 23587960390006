// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  height: 100px;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.footer-icon {
  margin-right: 30px;
}

.footer h3 {
  margin: 0;
  font-size: 1.2em;
}

.footer p {
  margin: 0;
  font-size: 1em;
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,cAAc;AAChB","sourcesContent":[".footer {\r\n  background-color: #000;\r\n  color: #fff;\r\n  text-align: center;\r\n  padding: 20px 0;\r\n  height: 100px;\r\n}\r\n\r\n.footer-content {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  height: 100%;\r\n}\r\n\r\n.footer-icon {\r\n  margin-right: 30px;\r\n}\r\n\r\n.footer h3 {\r\n  margin: 0;\r\n  font-size: 1.2em;\r\n}\r\n\r\n.footer p {\r\n  margin: 0;\r\n  font-size: 1em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
