import React from 'react';
import './MainContent.css';
import Footer from './Footer';

import heroImage from '../images/Home/mainimage.png';
import ashlogo from '../images/Home/ashlogo2.png';

const past_initiatives = [
  {
    title: "Children's Pulmonary Hospital in Tryavna, Bulgaria",
    date: "June 2020",
    amount: "$400",
    image: 'bulgariahospital.jpg',
    link: '/Tryavna'
  },
  {
    title: "Kyiv Center for Pediatric Cardiology and Cardiac Surgery",
    date: "August 2020",
    amount: "$1200",
    image: 'kyivhospital.jpg',
    link: '/Kyiv'
  },
  {
    title: "Lviv's Early Childhood Pediatric Center at St. Nicholas' Hospital",
    date: "August 2022",
    amount: "$2500",
    image: 'lvivhospital.jpg',
    link: '/Lviv'
  },
  {
    title: "Western Ukrainian Specialized Children's Medical Centre",
    date: "September 2023",
    amount: "$2000",
    image: 'hospital2new.PNG',
    link: '/Lviv2'
  },
  {
    title: "Western Ukrainian Specialized Children's Medical Centre",
    date: "June 2024",
    amount: "$2000",
    image: 'hospital2new.PNG',
    link: '/Lviv3'
  }
];

const team = [
  {
    name: 'Daniel Razgonyaev',
    school: 'Stony Brook University',
    study: 'Chemical Engineering',
    image: 'dannew.PNG'
  },
  {
    name: 'Alvin Baranov',
    school: 'University of Miami',
    study: 'Biochemistry',
    image: 'al.PNG'
  },
  {
    name: 'Niyazi Kyose',
    school: 'Stony Brook University',
    study: 'Computer Science',
    image: 'n.jpg'
  },
  {
    name: 'Gabriella Polgur',
    school: 'Macalauy Honors Brooklyn College',
    study: 'Biology',
    image: 'gab.PNG'
  },
  {
    name: 'Oleksandr Prystupa',
    school: 'Long Island University',
    study: 'Pharmacy',
    image: 'sash.PNG'
  },
  {
    name: 'Volodymyr Semenov',
    school: 'University of Buffalo',
    study: 'Computer Engineering',
    image: 'vald.PNG'
  },
  {
    name: 'Ilya Svitsin',
    school: 'Binghampton University',
    study: 'Biochemistry',
    image: 'il.jpg'
  }
];

const MainContent = () => {
  return (
    <main className="main-content">
      <section className="banner">
        <h3>We have just completed our most recent campaign in Lviv! Stay tuned for further updates.</h3>
      </section>
      <section 
        className="main-image"
        style={{
          backgroundImage: `url(${heroImage})`
        }}
        >
        <div className="main-image-content">
          <h1>American Students for Health</h1>
          <p>A nonprofit student-run fundraising organization</p>
        </div>
      </section>
      <section className="about-section">
        <img src={ashlogo} alt="ASH" className="about-logo" />
        <p>
          American Students for Health is a passionate collective of students committed to elevating the standard of medical care for children in Eastern European hospitals. Through our dedicated fundraising efforts over the past four years, we’ve garnered the generous support of our donors, amassing over $8,000. These funds have enabled us to acquire an array of essential supplies, ensuring these young patients receive the finest care available. Explore our previous initiatives below and connect with us on social media to witness the power of collaboration, and your generosity in action.
        </p>
      </section>
      <section className="initiatives-section">
        <h2>Our Past Initiatives</h2>
        <div className="initiatives-row">
          {past_initiatives.slice(0, 3).map((initiative, index) => (
            <div className="initiative-card" key={index}>
              <img src={require(`../images/Home/${initiative.image}`)} alt={initiative.title} />
              <h3>{initiative.title}</h3>
              <p>Date Held: {initiative.date}</p>
              <p>Total Amount Raised: {initiative.amount}</p>
              <a href={initiative.link} className="learn-more">Learn More</a>
            </div>
          ))}
        </div>
        <div className="initiatives-row">
          {past_initiatives.slice(3, 5).map((initiative, index) => (
            <div className="initiative-card" key={index}>
              <img src={require(`../images/Home/${initiative.image}`)} alt={initiative.title} />
              <h3>{initiative.title}</h3>
              <p>Date Held: {initiative.date}</p>
              <p>Total Amount Raised: {initiative.amount}</p>
              <a href={initiative.link} className="learn-more">Learn More</a>
            </div>
          ))}
        </div>
      </section>
      <section className="news">
        <h2>ASH In The News!</h2>
        <a href="https://golossokal.com.ua/en/zdorovia/studenty-niu-yorka-zibraly-koshty-na-aparat-dlia-poriatunku-ukrainskykh-ditok-u-lvovi.html" target="_blank">Golossokal Article</a>
        <a href="https://zaxid.net/studenti_nyu_yorka_zibrali_groshi_na_aparat_dlya_poryatunku_malenkih_ukrayintsiv_n1553447" target="_blank">Zaxid Article</a>
        <a href="https://youtu.be/uIBrS66qP-Q?t=676" target="_blank">Interview with Суспільне</a>
      </section>
      <section className="team-section">
        <h2>The ASH Team</h2>
        <div className="team-row">
          {team.slice(0, 3).map((member, index) => (
            <div className="team-member" key={index}>
              <img src={require(`../images/Home/${member.image}`)} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.school}</p>
              <p>{member.study}</p>
            </div>
          ))}
        </div>
        <div className="team-row">
          {team.slice(3, 6).map((member, index) => (
            <div className="team-member" key={index}>
              <img src={require(`../images/Home/${member.image}`)} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.school}</p>
              <p>{member.study}</p>
            </div>
          ))}
        </div>
        <div className="team-row">
          {team.slice(6, 7).map((member, index) => (
            <div className="team-member" key={index}>
              <img src={require(`../images/Home/${member.image}`)} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.school}</p>
              <p>{member.study}</p>
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </main>
  );
}

export default MainContent;
